import React from "react";
import FeaturedCard from "../FeaturedCard";
import Header, { HeaderVariant } from "../../../components/Header";
import SimpleCard from "../SimpleCard";

interface FeaturedBlogListProps {
  publications: any;
  title?: string;
  CTA?: any | null;
  ctaPosition?: any;
  color?: string;
  ctaSize?: string;
}

const FeaturedBlogList: React.FC<FeaturedBlogListProps> = props => {
  const { publications, title, CTA, ctaPosition, ctaSize, color } = props;

  const ctaColumnSize = {
    small: "col-span-6 md:col-span-3 lg:col-span-2",
    medium: "col-span-6 md:col-span-3 lg:col-span-4",
    large: "col-span-6"
  };

  const filteredPublications = React.useMemo(() => {
    return publications?.filter(
      (p: any) => p.slug !== null && p.title !== null
    );
  }, []);

  return (
    <>
      <Header className="md:mb-3 text-sm md:text-sm" variant={HeaderVariant.h1}>
        <span
          className={`font-medium text-2xl text-transparent mb-0 bg-gradient-to-r from-indigo-1 to-purple-4 bg-clip-text`}
        >
          Perpetua Blog
        </span>
      </Header>
      <Header variant={HeaderVariant.h2}>
        <span
          className={`${color} text-4xl flex mb-12 font-semibold lg:font-bold lg:mb-16 lg:text-5xl`}
        >
          {title}
        </span>
      </Header>
      <ul className="grid grid-cols-6 pb-6 sm:-mx-4">
        {filteredPublications?.map((item, i) => {
          if (i < 1) {
            return (
              <div key={i} className="col-span-6 sm:px-4">
                <FeaturedCard color={color} publication={item?.node ?? item} />
              </div>
            );
          }
          if (CTA && i === ctaPosition) {
            return (
              <div
                key={i}
                className={`hidden md:block sm:px-4 ${
                  ctaSize ? ctaColumnSize[ctaSize] : ctaColumnSize["small"]
                }`}
              >
                <CTA />
              </div>
            );
          } else {
            return (
              <div
                key={i}
                className="hidden md:block col-span-6 sm:col-span-3 md:col-span-2 sm:px-4"
              >
                <SimpleCard color={color} publication={item?.node ?? item} />
              </div>
            );
          }
        })}
      </ul>
    </>
  );
};

export default FeaturedBlogList;
