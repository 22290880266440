import { graphql } from "gatsby";
import React, { useMemo } from "react";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import BlogLayout from "../containers/BlogLayout";
import SimpleRecommendations from "../components/Blog/SimpleRecommendations";
import GradientSection from "../containers/GradientSection";
import EmbeddedCta from "../components/Blog/EmbeddedCta";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import { BreadcrumbPageType, formatBreadcrumb } from "../utils/breadcrumb";

const Blog = props => {
  const { data, pageContext } = props;
  const topStories = data?.featuredhome?.edges[0]?.node?.post;
  const { t } = useTranslation();

  const updatedCrumbs = useMemo(
    () => formatBreadcrumb(pageContext, BreadcrumbPageType.BlogIndex),
    []
  );

  return (
    <Layout inlineSwitcher hideNav={true}>
      <SEO
        title={"eCommerce Advertising Blog"}
        description={
          "Perpetua's blog for eCommerce advertising news, insights tips and content for sellers, brands and agencies looking to grow their business."
        }
      />
      <div className="max-w-8xl mx-auto mt-48 px-6 md:px-4">
        <CustomBreadcrumb crumbs={updatedCrumbs} />
      </div>

      <BlogLayout publications={data} />
      <GradientSection className="px-0 pt-12 bg-gradient-to-b from-gray-6 to-indigo-3-light">
        {topStories?.length > 0 && (
          <SimpleRecommendations
            publications={topStories
              .filter((item: any) => item.slug !== null)
              .slice(0, 3)}
            title={t("Top Stories")}
          />
        )}
        <div className="mx-[-24px] sm:mx-[0px] pt-18 pb-0 sm:pb-12 sm:pt-12">
          <EmbeddedCta
            fullWidth
            buttonText={t("Sign Up")}
            title={t("Insights to help grow profit")}
            textFieldPlaceholder={t("Email address")}
            successText={t(
              "Thanks for signing up! We promise only the best updates and growth rocket fuel coming your way."
            )}
          />
        </div>
      </GradientSection>
    </Layout>
  );
};

export default Blog;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    featuredhome: allContentfulFeaturedBlogPosts(
      limit: 6
      filter: {
        contentful_id: { eq: "2kwd54qSBW0e5vvqJ45JTG" }
        node_locale: { eq: $language }
        title: { ne: null }
      }
      sort: { order: DESC, fields: post___publishDate }
    ) {
      edges {
        node {
          post {
            node_locale
            id
            slug
            title
            bodyPreview
            bannerImage {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            categories {
              name
              contentful_id
            }
            timeToRead
            publishDate(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
    news: allContentfulPublication(
      limit: 6
      filter: {
        categories: {
          elemMatch: { contentful_id: { eq: "7aYdTCTVel7a9y8NbjjSrX" } }
        }
        node_locale: { eq: $language }
        title: { ne: null }
      }
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          slug
          title
          node_locale
          bodyPreview
          bannerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          categories {
            name
            contentful_id
          }
          timeToRead
          publishDate(formatString: "MMMM DD, YYYY")
        }
      }
    }
    customers: allContentfulPublication(
      limit: 6
      filter: {
        categories: {
          elemMatch: { contentful_id: { eq: "3nbXC9nQwMGFQcqxSdcepD" } }
        }
        node_locale: { eq: $language }
        title: { ne: null }
      }
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          slug
          title
          node_locale
          bodyPreview
          bannerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          categories {
            name
            contentful_id
          }
          timeToRead
          publishDate(formatString: "MMMM DD, YYYY")
        }
      }
    }
    retail: allContentfulPublication(
      limit: 6
      filter: {
        categories: {
          elemMatch: { contentful_id: { eq: "8aV86EmV3vcLozvMm6lCU" } }
        }
        node_locale: { eq: $language }
        title: { ne: null }
      }
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          slug
          title
          node_locale
          bodyPreview
          bannerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          categories {
            name
            contentful_id
          }
          timeToRead
          publishDate(formatString: "MMMM DD, YYYY")
        }
      }
    }
    featured: allContentfulFeaturedBlogPosts(
      limit: 6
      filter: {
        contentful_id: { eq: "3Vai53F9aDQHjmsfFDReF3" }
        node_locale: { eq: $language }
      }
      sort: { order: DESC, fields: post___publishDate }
    ) {
      edges {
        node {
          post {
            id
            slug
            title
            node_locale
            bodyPreview
            node_locale
            bannerImage {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            categories {
              name
              contentful_id
            }
            timeToRead
            publishDate(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
    strategy: allContentfulPublication(
      limit: 6
      filter: {
        categories: {
          elemMatch: { contentful_id: { eq: "2jMi4GaKW17xMUOYJUuvSb" } }
        }
        node_locale: { eq: $language }
        title: { ne: null }
      }
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          slug
          node_locale
          title
          bodyPreview
          bannerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          categories {
            name
            contentful_id
          }
          timeToRead
          publishDate(formatString: "MMMM DD, YYYY")
        }
      }
    }
    datainsights: allContentfulPublication(
      limit: 6
      filter: {
        categories: {
          elemMatch: { contentful_id: { eq: "5Bjfc0ZnNWhFDoSp0BQk1h" } }
        }
        node_locale: { eq: $language }
        title: { ne: null }
      }
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          slug
          node_locale
          title
          bodyPreview
          bannerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          categories {
            name
            contentful_id
          }
          timeToRead
          publishDate(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;
