import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import Section from "../../../containers/Section";
import Socials from "../Socials/index";
import Search from "../Search";
import cn from "classnames";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import EmbeddedCtaPop from "../EmbeddedCtaPop";
import { useI18next } from "gatsby-plugin-react-i18next";

interface AnchorNavigationProps {
  items: any;
  active?: string;
  searchQuery?: any;
  setSearchQuery?: any;
  spacing?: string;
  videoPage?: boolean;
}

const spacingStyles = {
  left: "justify-around gap-x-8 sm:justify-start xl:gap-18",
  evenly: "justify-around lg:justify-between"
};

const AnchorNavigation: React.FC<AnchorNavigationProps> = props => {
  const [atTopOfPage, setAtTopOfPage] = useState<boolean>(false);
  const {
    items,
    active,
    setSearchQuery = null,
    spacing = "evenly",
    videoPage = false
  } = props;
  const navRef = useRef(null);
  const { language } = useI18next();
  const showBanner = language === "en-US";

  let initialPosition = navRef?.current?.offsetTop;

  const handleScroll = () => {
    if (navRef && navRef?.current?.offsetTop > initialPosition) {
      setAtTopOfPage(true);
    } else {
      setAtTopOfPage(false);
    }
  };

  const handleResize = () => {
    initialPosition = navRef?.current?.offsetTop;
  };

  useEffect(() => {
    initialPosition = navRef?.current?.offsetTop;
    window?.addEventListener("scroll", handleScroll);
    window?.addEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        ref={navRef}
        className={cn(
          "bg-white md:sticky z-50 border-b border-gray-5",
          showBanner ? "top-[90px] lg:top-[99px]" : "top-[60px]",
          { "shadow-xl ease-in transition duration-300": atTopOfPage }
        )}
      >
        <Section className="relative blogNavigation grid grid-cols-12 items-center py-4">
          <ul
            className={cn(
              "hidden col-span-12 lg:col-span-8 flex-wrap mb-3 md:flex",
              spacing && spacingStyles[spacing]
            )}
          >
            {items.map((item, i) => {
              let textNormalize = item.text.split(" ").join("-").toLowerCase();
              return (
                <li key={i} className="flex group py-1 w-28 md:w-auto mr-8">
                  <AnchorLink
                    className={cn(
                      active == textNormalize
                        ? item.textColor
                        : `${item.textColorHover}`,
                      "font-medium text-base text-center w-full"
                    )}
                    key={i}
                    to={item.link}
                  >
                    {item.text}
                  </AnchorLink>
                </li>
              );
            })}
          </ul>
          <div className="col-span-12 lg:col-span-4 flex justify-end">
            <div className="hidden lg:block">
              <Socials />
            </div>
          </div>
          <div
            className={cn(
              "col-span-12 flex justify-between items-center",
              !setSearchQuery &&
                "justify-center md:justify-between lg:justify-end"
            )}
          >
            {setSearchQuery && (
              <div className="hidden relative rounded-md min-w-[278px] md:block mr-6">
                {language === "en-US" && (
                  <Search setSearchQuery={setSearchQuery} />
                )}
              </div>
            )}
            <div className="lg:hidden">
              <Socials />
            </div>
            <EmbeddedCtaPop />
          </div>
        </Section>
      </div>
    </>
  );
};

export default AnchorNavigation;
