import React, { useState } from "react";
import AnchorNavigation from "../../components/Blog/AnchorNavigation";
import GradientSection from "../GradientSection";
import BlogList from "../../components/Blog/BlogList";
import FeaturedBlogList from "../../components/Blog/FeaturedBlogList";
import SimpleRecommendations from "../../components/Blog/SimpleRecommendations";
import NavigationBar from "../../components/NavigationBar";
import { NavigationBarVariant } from "../../components/NavigationBar/models";
import BlogCta from "../../components/Blog/BlogCTA";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";

const BlogLayout = props => {
  const data = props.publications;

  const { t } = useTranslation();

  // Article types to pass into the blog list variations
  const newsArticles = data?.news?.edges;
  const customerArticles = data?.customers?.edges;
  const retailArticles = data?.retail?.edges;
  const featuredArticles = data?.featured?.edges[0]?.node?.post;
  const strategy = data?.strategy?.edges;
  const dataInsights = data?.datainsights?.edges;

  const sections: any = [
    {
      title: t("News"),
      anchor: t("news"),
      ctaPosition: 4,
      gradientWrapperClasses: "py-16 lg:py-22 bg-gradient-to-t from-blue-3",
      color: "text-gray-4-dark",
      button: "bg-gray-4-dark",
      publications: newsArticles,
      link: `#${t("news")}`,
      text: t("News"),
      textColor: "text-black",
      textColorHover: "hover:text-black"
    },
    {
      publications: retailArticles,
      anchor: t("retail-media"),
      gradientWrapperClasses: "py-16 lg:py-22 bg-gradient-to-b from-purple-3",
      title: t("Retail Media"),
      button: "bg-indigo-1",
      color: "text-indigo-1",
      ctaPosition: 5,
      ctaSize: "small",
      cta: () => {
        return (
          <BlogCta
            title={t("Take your digital retail  advertising to the next level")}
            variant="indigo"
            buttonText={t("Get Started")}
            buttonLink={t("https://app.perpetua.io/signup")}
            ctaSize="small"
          />
        );
      },
      link: `#${t("retail-media")}`,
      text: t("Retail Media"),
      textColor: "text-indigo-1",
      textColorHover: "hover:text-indigo-1"
    },
    {
      publications: customerArticles,
      title: t("Customer Stories"),
      anchor: t("customer-stories"),
      color: "text-green-1",
      button: "bg-green-1",
      gradientWrapperClasses: "py-16 lg:py-22 bg-gradient-to-b from-green-3",
      link: `#${t("customer-stories")}`,
      text: t("Customer Stories"),
      textColor: "text-green-1",
      textColorHover: "hover:text-green-1"
    },
    {
      publications: strategy,
      anchor: t("strategy"),
      gradientWrapperClasses: "py-16 lg:py-22 bg-gradient-to-b from-red-3",
      title: t("Strategy"),
      button: "bg-red-1",
      color: "text-red-1",
      link: `#${t("strategy")}`,
      text: t("Strategy"),
      textColor: "text-red-1",
      textColorHover: "hover:text-red-1"
    },
    {
      publications: dataInsights,
      title: t("Data & Insights"),
      anchor: t("data-insights"),
      color: "text-orange-4",
      button: "bg-orange-4",
      gradientWrapperClasses: "py-16 lg:py-22 bg-gradient-to-b from-orange-3",
      link: `#${t("data-insights")}`,
      text: t("Data & Insights"),
      className: "text-orange-4",
      textColorHover: "hover:text-orange-4"
    }
  ];

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [elRefs, setElRefs] = React.useState([]);
  const [activeSection, setActiveSection] = React.useState<string>("");

  function isVisible(ele, height) {
    const { top, bottom } = ele?.current?.getBoundingClientRect();
    return (top > 0 || bottom > 0) && top < height;
  }

  React.useEffect(() => {
    // add or remove refs
    setElRefs(elRefs => sections.map((_, i) => elRefs[i] || React.createRef()));
  }, [sections.length]);

  React.useEffect(() => {
    window?.addEventListener("scroll", () => {
      const currentScroll = window?.pageYOffset;
      elRefs?.map((item: any, i: number) => {
        if (item && item.current) {
          let divTop = item.current.offsetTop - item.current.offsetHeight / 3;
          let divBottom =
            item.current.offsetTop +
            item.current.offsetHeight +
            item.current.offsetHeight / 3;
          if (
            isVisible(item, window?.innerHeight) &&
            divTop < currentScroll &&
            currentScroll < divBottom
          ) {
            setActiveSection(item.current.id);
          }
        }
      });
    });
  }, [elRefs]);

  const filteredSections = sections.filter((item: any) => {
    return item.publications && item.publications.length > 0;
  });

  return (
    <>
      <NavigationBar
        setSearchQuery={setSearchQuery}
        variant={NavigationBarVariant.blog}
        blogNav={filteredSections}
      />
      <GradientSection className="pt-6 lg:pt-8 md:pb-22 bg-gradient-to-t from-blue-3">
        <FeaturedBlogList
          publications={featuredArticles}
          title={t("Featured stories")}
          color="text-gray-4-dark"
        />
      </GradientSection>
      <GradientSection className="block px-0 pt-12 pb-20 bg-gradient-to-b from-gray-6 to-indigo-3-light md:hidden">
        <SimpleRecommendations
          publications={featuredArticles?.slice(0, 3)}
          title={t("Top Stories")}
        />
      </GradientSection>
      <AnchorNavigation
        spacing="left"
        items={filteredSections}
        active={activeSection}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      {sections?.map((section, i) => {
        if (section.publications && section.publications.length > 0) {
          return (
            <div key={i} id={section.anchor} ref={elRefs[i]}>
              <GradientSection
                key={i}
                className={section.gradientWrapperClasses}
              >
                <BlogList
                  publications={section.publications}
                  title={section.title}
                  ctaPosition={section.ctaPosition}
                  CTA={section.cta}
                  color={section.color}
                  button={section.button}
                  ctaSize={section.ctaSize}
                  limit={5}
                />
              </GradientSection>
            </div>
          );
        }
      })}
    </>
  );
};

export default BlogLayout;
